import React from 'react'
import { Grid, Button } from '@material-ui/core'
import Logo from '../../images/logo.png'
import styled from 'styled-components'
import { Link } from 'gatsby'

const currentYear = new Date().getFullYear()

const StyledFooter = styled.footer`
    margin: ${(props) => (props.lowFootprint ? '0 auto' : '200px auto 100px')};
    max-width: 1100px;
    padding: 20px;
    text-align: center;
    h2 {
        font-size: 1.7rem;
        font-weight: 600;
        text-align: left;
        text-transform: uppercase;
        width: 100%;
    }
    h2,
    h3 {
        text-align: left;
        text-transform: uppercase;
    }
    a {
        color: #449cd7;
        &:visited {
            color: #449cd7;
        }
    }
    ul,
    a {
        text-align: left;
    }
    ul {
        line-height: 25px;
    }
`

const Footer = ({ lowFootprint }) => {
    return (
        <StyledFooter lowFootprint={lowFootprint}>
            {!lowFootprint ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img src={Logo} alt="logo de soumissions-avocat.ca" />
                    </Grid>
                    <Grid item container xs={12} sm={6} spacing={4}>
                        <h2>Nos services</h2>
                        <Grid item xs={12} sm={6}>
                            <h3>
                                <Link to="/droit-familial/">
                                    Droit familial
                                </Link>
                            </h3>
                            <ul>
                                <li>Divorce/Séparation</li>
                                <li>Garde/Pension</li>
                            </ul>
                            <h3>
                                <Link to="/droit-civil-administratif-ou-travail/">
                                    DROIT CIVIL, ADMINISTRATIF & DU TRAVAIL
                                </Link>
                            </h3>
                            <ul>
                                <li>Responsabilité</li>
                                <li>Assurance</li>
                                <li>TAQ/CSST/SAAQ</li>
                            </ul>
                            <h3>
                                <Link to="/droit-immobilier/">
                                    Droit immobilier
                                </Link>
                            </h3>
                            <ul>
                                <li>Vente/Achat/Baux</li>
                                <li>Vice caché/Litige</li>
                            </ul>
                            <h3>
                                <Link to="/avocat-affaires-commercial-corporatif/">
                                    Droit commercial et corporatif
                                </Link>
                            </h3>
                            <ul>
                                <li>Convention d'actionnaire</li>
                                <li>Constitution de compagnie</li>
                            </ul>
                            <h3>
                                <Link to="/droit-penal-et-criminel/">
                                    Droit pénal & criminel
                                </Link>
                            </h3>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ul style={{ marginTop: '15px' }}>
                                <li>
                                    <Link to="/blog">
                                        Consultez nos publications
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/contact">CONTACTEZ-NOUS</Link>
                                </li> */}
                            </ul>
                            <ul
                                style={{
                                    margin: '15px 0',
                                    textAlign: 'center',
                                    lineHeight: '40px',
                                    fontSize: '25px',
                                }}
                            >
                                <li>
                                    <a href="tel:+15145008471">
                                        1 (514) 500-8471
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+15812044224">
                                        1 (581) 204-4224
                                    </a>
                                </li>
                            </ul>
                            <Button variant="contained" color="secondary">
                                Devenir partenaire
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        sm={6}
                        spacing={4}
                        style={{ alignContent: 'start' }}
                    >
                        <h2>Trouvez un avocat près de chez vous</h2>
                        <Grid item xs={12} sm={6}>
                            <ul>
                                <li>
                                    <Link to="/montreal/">Montréal</Link>
                                </li>
                                <li>
                                    <Link to="/longueuil-rive-sud/">
                                        Rive-Sud de Montréal
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/trouver-engager-meilleurs-avocats-rive-nord-montreal/">
                                        Rive-Nord de Montréal
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/regler-probleme-legal-meilleurs-avocats-longueuil/">
                                        Longueuil
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/laval/">Laval</Link>
                                </li>
                                <li>
                                    <Link to="/quebec/">Québec</Link>
                                </li>
                                <li>
                                    <Link to="/services-juridiques-meilleurs-avocats-levis/">
                                        Rive-Sud (Lévis)
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/consultation-services-juridiques-avocats-blainville/">
                                        Blainville
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/consultation-services-juridiques-avocats-mirabel/">
                                        Mirabel
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/conseils-juridiques-meilleurs-avocats-saint-hyacinthe/">
                                        Saint-Hyacinthe
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/avantages-engager-meilleurs-avocats-terrebonne/">
                                        Terrebonne
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/comparer-services-juridiques-meilleurs-avocats-brossard/">
                                        Brossard
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ul>
                                <li>
                                    <Link to="/engager-comparer-meilleurs-avocats-legal-drummondville/">
                                        Drummondville
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/engager-services-meilleurs-avocats-conseils-saguenay/">
                                        Saguenay
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services-juridiques-meilleurs-avocats-gatineau-conseils/">
                                        Gatineau
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/engager-meilleurs-avocats-saint-jerome/">
                                        Saint-Jérôme
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services-juridiques-avocats-saint-jean-sur-richelieu/">
                                        Saint-Jean-sur-Richelieu
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/comparer-services-soumissions-avocats-granby/">
                                        Granby
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services-juridiques-meilleurs-avocats-repentigny/">
                                        Repentigny
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/meilleurs-avocats-services-juridiques-sherbrooke-estrie/">
                                        Sherbrooke
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}></Grid>
                    <Grid item xs={12} sm={6}></Grid>
                </Grid>
            ) : null}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                    <span>Copyright © {currentYear} Soumissions Avocat.</span>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                    <span>
                        <Link to="/termes-et-conditions">
                            Termes et Conditions / Politique de Confidentialité
                        </Link>
                    </span>
                </Grid>
            </Grid>
        </StyledFooter>
    )
}

export default Footer
