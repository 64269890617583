import React from 'react'
import { Grid, Menu, MenuItem } from '@material-ui/core'
import styled from 'styled-components'
import { Link } from 'gatsby'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const Nav = styled.nav`
    display: flex;
    justify-content: center;
    a {
        color: black;
        &:visited {
            color: black;
        }
    }
    > div {
        align-items: center;
        display: flex;
        padding: 0 10px;
        position: relative;
        cursor: pointer;
        ul {
            border-top: 6px solid #449cd7;
            display: none;
            background-color: #053f57;
            position: absolute;
            top: 40px;
            width: 250px;
            z-index: 10;

            li {
                color: white;
                padding: 10px;
                a:hover {
                    color: #449cd7;
                }
                a {
                    color: white;
                    &:visited {
                        color: white;
                    }
                }
            }
        }
        &:hover {
            ul {
                display: block;
            }
        }
    }
    @media (max-width: 667px) {
        display: none;
    }
`

const TopMenu = ({}) => {
    return (
        <Nav>
            <div>
                <span>Soumissions</span>
                <KeyboardArrowDownIcon />
                <ul>
                    <li>
                        <Link to="/droit-civil-administratif-ou-travail/">
                            Droit civil, administratif ou du travail
                        </Link>
                    </li>
                    <li>
                        <Link to="/avocat-affaires-commercial-corporatif/">
                            Droit commercial et corporatif
                        </Link>
                    </li>
                    <li>
                        <Link to="/droit-familial/">Droit familial</Link>
                    </li>
                    <li>
                        <Link to="/droit-immobilier/">Droit immobilier</Link>
                    </li>
                    <li>
                        <Link to="/droit-penal-et-criminel/">Droit pénal</Link>
                    </li>
                </ul>
            </div>
            <div>
                <span>Partenaires</span>
                <KeyboardArrowDownIcon />
                <ul>
                    <li>
                        <Link to="/comparer-services-juridiques-meilleurs-avocats-brossard/">
                            Avocats à Brossard
                        </Link>
                    </li>
                    <li>
                        <Link to="/engager-comparer-meilleurs-avocats-legal-drummondville/">
                            Avocats à Drummondville
                        </Link>
                    </li>
                    <li>
                        <Link to="/services-juridiques-meilleurs-avocats-gatineau-conseils/">
                            Avocats à Gatineau
                        </Link>
                    </li>
                    <li>
                        <Link to="/laval/">Avocats à Laval</Link>
                    </li>
                    <li>
                        <Link to="/services-juridiques-meilleurs-avocats-levis/">
                            Avocats à Lévis & rive-sud
                        </Link>
                    </li>
                    <li>
                        <Link to="/regler-probleme-legal-meilleurs-avocats-longueuil/">
                            Avocats à Longueuil & rive-sud
                        </Link>
                    </li>
                    <li>
                        <Link to="/montreal/">Avocats à Montréal</Link>
                    </li>
                    <li>
                        <Link to="/quebec/">Avocats à Québec</Link>
                    </li>
                    <li>
                        <Link to="/trouver-engager-meilleurs-avocats-rive-nord-montreal/">
                            Avocats sur la Rive-Nord de Montréal
                        </Link>
                    </li>
                    <li>
                        <Link to="/conseils-juridiques-meilleurs-avocats-saint-hyacinthe/">
                            Avocats à Saint-Hyacinthe
                        </Link>
                    </li>
                    <li>
                        <Link to="/engager-services-meilleurs-avocats-conseils-saguenay/">
                            Avocats à Saguenay
                        </Link>
                    </li>
                </ul>
            </div>
            <div>
                <span>
                    <Link to="/contact">Contact</Link>
                </span>
            </div>
            <div>
                <span>
                    <Link to="/partenaire-avocat">Devenir Partenaire</Link>
                </span>
            </div>
        </Nav>
    )
}

export default TopMenu
