import React from 'react'
import Reset from './reset'
import Global from './global'
import { Helmet } from 'react-helmet'
import Header from '../Header'
import { ThemeProvider } from '@material-ui/core/styles'
import CustomSoumAvoTheme from './material-ui-custom-theme'
import Footer from '../Footer'
import favicon from '../../images/favicon.ico'
const descriptionDefault = 'Trouvez votre avocat à Montréal ou Québec.'
const titleDefault =
    "Comparez Tarifs & Honoraires d'Avocats & Services Légaux à Québec, Montréal - Soumissions Avocat"
const Layout = ({
    children,
    lowFootprint,
    title = titleDefault,
    description = descriptionDefault,
    location,
    image,
    noPhones,
    noindex,
    canonical,
}) => {
    return (
        <ThemeProvider theme={CustomSoumAvoTheme}>
            <Helmet>
                <meta charset="utf-8" />
                <meta http-equiv="x-ua-compatible" content="ie=edge"></meta>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossorigin
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;600&display=swap"
                    rel="stylesheet"
                />
                <link rel="shortcut icon" href={favicon} />
                <link
                    rel="canonical"
                    href={`https://soumissionsavocat.${
                        canonical ? canonical : 'ca'
                    }${location.pathname}`}
                ></link>
                <title>{title}</title>
                {noindex !== false ? (
                    <meta name="robots" content="noindex" />
                ) : null}
                <meta name="description" content={description} />
                <meta property="og:type" content="website"></meta>
                <meta property="og:site_name" content="Soumissions Avocat" />
                <meta property="og:description" content={description} />
                <meta property="og:title" content={title} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="og:locale" content="fr_CA" />
                <meta property="og:image" content={image} />
            </Helmet>
            <Header noPhones={noPhones} />
            {children}
            <Reset />
            <Global />
            <Footer lowFootprint={lowFootprint} />
        </ThemeProvider>
    )
}

export default Layout
