import React from 'react'
import Logo from '../../images/logo.png'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { Link } from 'gatsby'
import TopMenu from '../TopMenu'

const GridItem = styled(Grid)`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px;
    font-family: -apple-system, Montserrat, Arial, Tahoma, sans-serif;
    a {
        color: black;
        font-weight: 600;
        &:hover {
            color: #449cd7;
        }
    }
    @media (max-width: 667px) {
        :nth-child(1) {
            padding: 10px 0 0;
        }
        :nth-child(2) {
            padding: 10px 0 0;
            strong {
                font-size: 0.85rem;
            }
        }

        :nth-child(3) {
            display: none;
        }
        max-width: 100%;
    }
`
const CenterGrid = styled(GridItem)`
    text-align: center;
`

const Header = ({ noPhones }) => (
    <header style={{ maxWidth: '100%', padding: '5px 10px' }}>
        <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
            <GridItem item xs={4} sm={4}>
                <Link to="/">
                    <img
                        src={Logo}
                        style={{ width: '100%' }}
                        alt="Soumissions avocat logo"
                    />
                </Link>
            </GridItem>
            <CenterGrid item xs={8} sm={4}>
                <strong>
                    Trouvez un Avocat Selon Vos Critères parmi nos +250 Avocats
                    Partenaires partout au Quebec!
                </strong>
            </CenterGrid>
            {noPhones ? null : (
                <GridItem
                    item
                    xs={12}
                    sm={4}
                    style={{ flexDirection: 'column' }}
                >
                    <span>
                        <a href="tel:+15145008471">1 (514) 500-8471</a>
                    </span>
                    <span style={{ marginTop: '7px' }}>
                        <a href="tel:+15812044224">1 (581) 204-4224</a>
                    </span>
                </GridItem>
            )}
        </Grid>
        <TopMenu />
    </header>
)

export default Header
